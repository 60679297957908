<template>
  <component
    :is="isLinkValid(item.link) ? 't3-link' : 'div'"
    :to="item.link"
    class="product-icon-tile"
    :class="[{ static: !isLinkValid(item.link) }, variantColorClass(item)]"
  >
    <div class="product-icon-tile__wrapper">
      <div
        v-if="item.logo.length"
        class="product-icon-tile__icon-wrapper"
      >
        <UiImg
          :image="item.logo"
          class="product-icon-tile__icon"
          use-image-size
        />

        <SvgIconLinkBoxArrow v-if="showLinkIcon && isLinkValid(item.link)" />
      </div>

      <T3HtmlParser
        v-if="item.text"
        :content="item.text"
        class="product-icon-tile__desc"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import SvgIconLinkBoxArrow from '~ui/assets/icons/icon-link-box-arrow.svg'
import { UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import { isLinkValid } from '~ui/helpers/linkUtils'
import type { UiCeCaseStudyBannerTagProps, UiCeProductBoxesItemProps } from '~ui/types'

type ItemType = UiCeProductBoxesItemProps | UiCeCaseStudyBannerTagProps

defineProps<{
  item: ItemType
  showLinkIcon?: boolean
}>()

const variantColorClass = (item: ItemType) =>
  useTheming(item.variant, 'variant')
</script>

<style lang="scss">
.product-icon-tile {
  padding: rem(3px);
  border-radius: em(4px);
  background: color(white);
  box-shadow:
    0 2px 20px 0 hsl(0 0% 0% / 3%),
    0 8px 16px hsl(0 0% 0% / 4%);
  transition:
    box-shadow 0.25s,
    background 0.25s;
  min-height: rem(180px);

  $base: &;

  &:is(:hover, :focus):not(.static) {
    background: linear-gradient(
      to bottom,
      var(--variant__gradient-700) 40%,
      var(--variant__gradient-300)
    );
    box-shadow:
      0 4px 20px 0 hsl(0 0% 0% / 4.5%),
      0 16px 16px hsl(0 0% 0% / 6%);

    & #{$base}__wrapper::before {
      background-color: var(--variant__gradient-100, color(white));
    }

    & #{$base}__icon-wrapper svg > path {
      fill: var(--variant__theme-color, color(silver));
    }
  }

  &__wrapper {
    position: relative;
    height: 100%;
    padding: rem(19px);
    border-radius: em(2px);
    background-color: color(white);
    isolation: isolate;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: z-index(below);
      background-color: color(white);
      transition: background-color 0.25s;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20px);
    width: 100%;

    & svg {
      height: 28px;
      width: 28px;

      path {
        fill: color(silver);
        transition: fill 0.25s;
      }
    }
  }

  &__icon {
    max-height: rem(46px);
    max-width: min-content;
    object-fit: contain;
    width: 100%;
  }

  &__desc p {
    color: initial;
    font-size: rem(16px);
    margin: 0;
  }
}
</style>
